.dropdown {
  display: sticky;
  padding: 1rem;

  .select-item {
    display: block;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    
    &:hover {
      background-color: #ddd;
    }
  }

  .select-menu {
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 3;
    transform: scaleY(0);
    transform-origin: 100% 0%;

    &:hover {
      transform: scaleY(1);
    }
  }
}

.search-input {
  box-sizing: border-box;
  background-image: none;
  font-size: 16px;
  font-weight: bolder;
  padding: 14px 20px 12px 45px;
  border: 1px solid #ccc;
  border-radius: .5rem;

  &:focus {
    outline: 3px solid #ddd;
    
    & + .select-menu {
      height: auto;
      transform: scaleY(1);
      transition: all .3s ease-in-out;
      transition-delay: 0.1s;
    }
  }


}

.hide {
  display: none;
}

#dropdown-container {
  position: relative;

  .magnifying-glass-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: .7rem;
    opacity: .5;
    font-weight: bolder;

    &:hover {
      opacity: .7;
    } 
  }
  
  .erase-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    opacity: .1;
    font-weight: bolder;

    &:hover {
      opacity: 1;
    }

    &:active {
      color: blue;
    } 
  }

  &:hover {
    .magnifying-glass-icon { 
      opacity: 1;
    }
  } 
}