section, header {
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;  
}

.header {
  display: block;
  background-color: #0fa3b1cc;
  color: white;
}

.title {
  display: block;
  text-align: center;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.title-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.verbear {
  width: 3rem;
  position: absolute;
}

.verb-selection-container {
  display: flex;
  justify-content: center;  
  width: 100%;
  background-color: #F9F7F3;
}

.control-container {
  display: flex;
  justify-content: center;  
  width: 100%;
  background-color: #B5E2FA;
}

.card-area {
  flex: 1;
  background-color: #f3e7b7;
}

.footer {
  display: flex;
  justify-content: center;  
  width: 100%;
  background-color: #F7A072;
}