.card-area {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 400px;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    flex: 1;

    & > div {
      margin: .7rem;
    }
}

.card-count {
  background-color: azure;
  padding: 1rem;
  border-radius: .5rem;
  margin:  1rem;
  z-index: 2;
}

.verbear-image {
  position: fixed;
  width: 200px;
  height: 200px;
  background-image: url('../../verbear.svg');
  box-shadow: inset 0 0 0 100px #f3e7b7d7;
}