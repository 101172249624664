.card {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 280px;
  height: 120px;
  perspective: 1000px;
  transition: scale .5s ease-in-out;
    
  &:hover {
    transform: scale(1.1);

    .controls {
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  &.flipped .card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,1);
    }
  }

  .flip-card-front {
    color: black;
  }

  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
    position: relative;

    .tense {
      position: absolute;
      padding: .5rem .75rem;
      top: 0;
      right: 0;
      font-style: italic;
      font-weight: 700;
      font-size: .8rem;
    }
  }
}


.eng {
  display: block;
  margin: .4rem;
  font-size: 1.4rem;
  font-weight: 600;
}