.control-center {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100vw;
    min-width: 400px;
    flex-wrap: wrap;

    .title {
      margin-left: 1rem;
    }
    
    .checkboxInput {
      position: absolute;
      cursor: pointer;
      height: 1.5rem;
      width: 1.5rem;
      background: lightcyan;
    }

    .checkboxLabel {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .contolArea {
      display: inline-flex;
      flex-wrap: wrap;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .checkboxContol {
      min-width: 10rem;
      padding: .5rem;
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 1.2rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
}

@media screen and (min-width: 600px) {
  .contolArea {
    justify-content: center;
    border: none;
  }
}