* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: Arial, serif;
  text-transform: uppercase;
}

.card_bg_present {
  background-color: #6ae4a7;
  background: linear-gradient(90deg, #33eb8f 0%, #6ae4a7 100%);
}

.card_bg_present_back {
  background-color: #049c50;
  background: linear-gradient(90deg, rgb(9, 95, 52) 0%, #049c50 100%);
}

.card_bg_preterite {
  background-color: #b5e4ff;
  background: linear-gradient(90deg, #e6f1f8 0%, #b5e4ff 100%);
}

.card_bg_preterite_back {
  background-color: #2274A5;
  background: linear-gradient(90deg, #062d44 0%, #2274A5 100%);
}

.card_bg_imperfect {
  background-color: #fdbb94; 
  background: linear-gradient(90deg, #f8dfd0 0%, #fdbb94 100%);
}

.card_bg_imperfect_back {
  background-color: #a13e04;
  background: linear-gradient(90deg, #7e3810 0%, #a13e04 100%);
}

.card_bg_future {
  background-color: #fde99a;
  background: linear-gradient(90deg, #f5eed1 0%, #fde99a 100%);
}

.card_bg_future_back {
  background-color: #fde99a;
  background: linear-gradient(90deg, #836902 0%, #c59f08 100%);
}

.card_bg_conditional {
  background-color: #fa9bc7;
  background: linear-gradient(90deg, #f0ccdd 0%, #fa9bc7 100%);
}

.card_bg_conditional_back {
  background-color: #bd0359;
  background: linear-gradient(90deg, #7a0f41 0%, #bd0359 100%);
}